<template>
  <div>
    <b-card
      :style="`width: ${cardWidth}`"
      no-body
    >
      <template #header>
        <b-card-title>Events and Timeline</b-card-title>
      </template>
      <b-card-body>
        <b-card-sub-title class="pb-2">
          Past, future and hypothetical events showing cashflows and maturity. Includes simulation facility to test different future evolution scenarios
        </b-card-sub-title>

        <b-form-group
          v-if="isLive && !forPrint"
          label="Show future evolution"
          label-for="futureSwitch"
          label-cols="2"
          content-cols="2"
        >
          <b-form-checkbox
            id="futureSwitch"
            v-model="isFuture"
            switch
            :disabled="dataLoading"
            @change="getData"
          />
        </b-form-group>

        <div
          v-if="isFuture"
          class="my-1"
        >
          <b-row>
            <b-col
              cols="12"
              lg="12"
            >
              <div>
                <b-card-sub-title class="py-50">
                  Enter instantaneous shift and annualised drift to be applied to each underlying spot process
                </b-card-sub-title>
                <div class="my-1 d-flex flex-wrap">
                  <span class="mr-1 align-self-center mt-1">Shift</span>
                  <div class="d-flex flex-column mr-2">
                    <span class="small shift-drift-input-label">Preset values</span>
                    <b-button-group>
                      <b-button
                        v-for="shift in shifts"
                        :key="shift"
                        :variant="parseFloat(parameters.shift) === shift ? 'primary' : 'outline-primary'"
                        class="shift-drift-button"
                        :disabled="dataLoading"
                        @click.prevent="parameters.shift=shift"
                      >
                        {{ shift }}%
                      </b-button>
                    </b-button-group>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="small shift-drift-input-label">User defined</span>
                    <b-input-group
                      append="%"
                      :class="'shift-drift-input input-group-merge ' + (dataLoading ? 'disabled' : '')"
                    >
                      <b-form-input
                        v-model="parameters.shift"
                        type="number"
                        size="md"
                        :disabled="dataLoading"
                      />
                    </b-input-group>
                  </div>
                </div>

                <div class="my-1 d-flex flex-wrap">
                  <span class="mr-1 align-self-center">Drift</span>
                  <b-button-group class="mr-2">
                    <b-button
                      v-for="drift in drifts"
                      :key="drift"
                      :variant="parseFloat(parameters.drift) === drift ? 'primary' : 'outline-primary'"
                      class="shift-drift-button"
                      :disabled="dataLoading"
                      @click.prevent="parameters.drift=drift"
                    >
                      {{ drift }}%
                    </b-button>
                  </b-button-group>
                  <b-input-group
                    append="%"
                    :class="'shift-drift-input input-group-merge ' + (dataLoading ? 'disabled' : '')"
                  >
                    <b-form-input
                      v-model="parameters.drift"
                      type="number"
                      size="md"
                      :disabled="dataLoading"
                    />
                  </b-input-group>
                </div>

                <div class="py-1">
                  Hypothetical payoff: {{ maturityInfo.payoff }}, Time to maturity: {{ maturityInfo.duration }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <vue-apex-charts
          v-if="!error"
          type="line"
          height="400"
          :options="eventsChartOptions"
          :series="chartData.SHOWDATA.DATA"
        />
        <error-display
          v-else
          @action-clicked="getData"
        />
      </b-card-body>
    </b-card>

    <div class="pagebreak" />

    <b-card
      no-body
      class="events-collapse"
    >
      <custom-collapsable
        title="Event Details"
        :is-visible="forPrint"
        :hide-dropdown-icon="forPrint"
      >
        <template #header-action>
          <div
            v-if="!forPrint"
            class="d-flex align-items-center"
            @click.stop="null"
          >
            <span class="mr-1">Show Past Events</span>
            <b-form-checkbox
              v-model="showPastEvents"
              name="check-button"
              switch
              inline
            />
          </div>
        </template>
        <b-card
          v-if="!error"
          no-body
        >
          <template v-if="!forPrint">
            <div class="font-weight-bold mb-1">
              {{ eventsData.length }} results found <span v-if="eventsData.length > perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
            </div>
            <b-input-group class="mb-2 input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                placeholder="Search (date, type or event)"
                :disabled="false"
              />
            </b-input-group>
          </template>
          <b-table
            responsive
            :fields="eventsDataLiveFields"
            :items="eventsData"
            :current-page="currentPage"
            :per-page="forPrint ? eventsData.length : perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-compare="customSortCompare"
            table-class="events-table-base"
            tbody-tr-class="events-table-row"
          >
            <template #cell(eventname)="data">
              <div class="events-table-grid-container">
                <div
                  v-for="(eventName, index) in data.item.eventname"
                  :key="index"
                  :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                >
                  <span>{{ eventName }}</span>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <div class="events-table-grid-container">
                <div
                  v-for="(status, index) in data.item.status"
                  :key="index"
                  :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                >
                  <feather-icon
                    v-if="status"
                    :icon="status"
                    :class="`text-${data.item.color[index]} mr-1`"
                    size="18"
                  />
                  <span v-if="data.item.spot[index] && data.item.level[index]">
                    {{ Math.round((data.item.level[index] / data.item.spot[index]) * 100) }}%
                  </span>
                </div>
              </div>
            </template>
            <template #cell(probability)="data">
              <div class="events-table-grid-container">
                <div
                  v-for="(probability, index) in data.item.probability"
                  :key="index"
                  :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                >
                  <span v-if="probability || probability === 0">
                    {{ Math.round(parseFloat(probability * 100)) }}%
                  </span>
                </div>
              </div>
            </template>
          </b-table>
          <div
            v-if="!forPrint"
            class="py-2 d-flex justify-content-between"
          >
            <div class="my-2">
              <span class="pb-1">Rows Per Page</span>
              <b-form-select
                v-model="perPage"
                :options="perPageOption"
                class="pagination-select"
              />
            </div>

            <div class="my-2 d-flex flex-column justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="eventsFilterResult.length"
                :per-page="perPage"
                align="right"
                size="sm"
                class="my-0"
              />
            </div>
          </div>
          <loading-container v-if="dataLoading" />
          <no-data-container v-if="!dataLoading && (!eventsDataLive.CONTENT || eventsDataLive.CONTENT.length < 1)" />
        </b-card>
        <error-display
          v-else
          @action-clicked="getData"
        />
      </custom-collapsable>
    </b-card>
  </div>
</template>

<script>
import { $themeColors } from '@themeConfig'
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BTable,
  BFormCheckbox,
  BFormGroup,
  BButton,
  BButtonGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import NoDataContainer from '@/views/components/NoDataContainer.vue'
import CustomCollapsable from '@/views/components/CustomCollapsable.vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

const debounce = require('lodash/debounce')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BTable,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BButtonGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VueApexCharts,
    CustomCollapsable,
    ErrorDisplay,
    LoadingContainer,
    NoDataContainer,
  },
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    forPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLive: false,
      isFuture: false,
      parameters: { shift: 0, drift: 0 },
      shifts: [-50, -25, 0, 25, 50],
      drifts: [-10, -5, 0, 5, 10],
      maturityInfo: {
        payoff: '',
        duration: '',
      },
      chartData: {
        SHOWDATA: {
          DATA: [],
        },
        EVENTSDATA: {
          X: [],
          Y: [],
          POINTS: [],
        },
        YMIN: 0,
        YMAX: 100,
      },
      chartOptions: {
        noData: {
          text: 'Loading...',
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoomin: false,
              zoomout: false,
            },
          },
          zoom: {
            type: 'xy',
            enabled: true,
          },
        },
        colors: [$themeColors.primary, $themeColors.success, $themeColors.danger,
          $themeColors.secondary, $themeColors.info, $themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        grid: {
          yaxis: {
            // set to false to remove horizontal lines through graph
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        stroke: {
          width: 3,
          curve: 'smooth',
          lineCap: 'butt',
        },
        forecastDataPoints: {
          count: 128,
        },
        tooltip: {
          x: {
            formatter: val => this.formatDate(val),
          },
          shared: false,
          marker: false,
        },
        xaxis: {
          type: 'datetime',
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
            formatter: val => this.formatDate(val),
          },
          labels: {
            show: true,
            showDuplicates: false,
            formatter: val => this.formatDate(val),
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              style: {},
            },
            opposite: false,
            forceNiceScale: true,
          },
        ],
      },
      dataLoading: false,
      eventsDataLive: {
        HEADER: [],
        CONTENT: [],
      },
      cardWidth: '99.9%',
      perPage: 10,
      currentPage: 1,
      perPageOption: [5, 10, 15, 20, 50, 100],
      filter: '',
      sortBy: '',
      sortDesc: false,
      showPastEvents: false,
      error: false,
    }
  },
  computed: {
    eventsChartOptions() {
      const options = { ...this.chartOptions }

      options.yaxis[0].min = this.chartData.YMIN
      options.yaxis[0].max = this.chartData.YMAX
      options.xaxis.max = this.chartData.XMAX
      options.forecastDataPoints.count = this.chartData.forecastDataPointscount

      options.annotations = {
        position: 'back',
        xaxis: this.chartData.EVENTSDATA.X,
        yaxis: this.chartData.EVENTSDATA.Y,
        points: this.chartData.EVENTSDATA.POINTS,
      }

      // Keep point annotation labels near the right edge inside the canvas
      if (options.annotations.points.length) {
        const showdata = this.chartData.SHOWDATA.DATA

        let mind = new Date('3000-12-31').getTime()
        let maxd = 0
        showdata.forEach(series => {
          series.data.forEach(item => {
            const d = new Date(item.x).getTime()
            mind = Math.min(mind, d)
            maxd = Math.max(maxd, d)
          })
        })

        options.annotations.points.forEach((point, index) => {
          if ((maxd - point.x) / (maxd - mind) < 0.05) {
            options.annotations.points[index].label.textAnchor = 'end'
          }
        })
      }
      return options
    },
    eventsFilterResult() {
      let eventsData = cloneDeep(this.eventsDataLive.CONTENT)
      if (!this.showPastEvents) {
        eventsData = eventsData.filter(event => event.horizon !== 'Past')
      }

      if (this.filter && this.filter.length > 0) {
        const filteredByHorizon = eventsData.filter(item => (item.horizon.toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (item.eventdate.toLowerCase().search(this.filter.toLowerCase()) !== -1))

        eventsData.forEach((ed, i) => {
          // Check if eventData exists inside filteredByHorizon
          if (filteredByHorizon.find(item => isEqual(ed, item))) {
            return
          }

          let matchingRows = []

          // Filter by eventname
          ed.eventname.forEach((name, index) => {
            if (name.toLowerCase().search(this.filter) !== -1) {
              matchingRows.push(index)
            }
          })

          // Get unique matchingRows
          matchingRows = matchingRows.filter((value, index, self) => self.indexOf(value) === index)

          if (matchingRows.length > 0) {
            // Filter array columns
            eventsData[i].eventname = ed.eventname.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
            if (eventsData[i].probability) eventsData[i].probability = ed.probability.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
            if (eventsData[i].status) eventsData[i].status = ed.status.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
            if (eventsData[i].color) eventsData[i].color = ed.color.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
            if (eventsData[i].spot) eventsData[i].spot = ed.spot.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
            if (eventsData[i].level) eventsData[i].level = ed.level.filter((value, index) => typeof (matchingRows.find(element => element === index)) !== 'undefined')
          }
        })

        // Custom search for eventname and probability
        return eventsData.filter(item => (item.eventname.toString().toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (item.horizon.toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (item.eventdate.toLowerCase().search(this.filter.toLowerCase()) !== -1))
      }
      return eventsData
    },
    eventsDataLiveFields() {
      return this.eventsDataLive.HEADER.map(({ key, label }) => ({
        key,
        label,
        sortable: key === 'eventdate',
        tdClass: key === 'eventdate' || key === 'horizon' ? '' : 'p-0',
      }))
    },
    eventsData() {
      if (this.sortBy === 'probability') {
        return this.sortContent(this.eventsFilterResult, 'probability', this.sortDesc)
      }
      if (this.sortBy === 'eventname') {
        return this.sortContent(this.eventsFilterResult, 'eventname', this.sortDesc)
      }
      return this.eventsFilterResult
    },
    showing() {
      const start = (this.perPage * this.currentPage) - this.perPage + 1
      const end = (this.perPage * this.currentPage) > this.eventsData.length ? this.eventsData.length : (this.perPage * this.currentPage)
      return [start, end]
    },
  },
  watch: {
    'parameters.shift': function () {
      this.getData()
    },
    'parameters.drift': function () {
      this.getData()
    },
  },
  mounted() {
    // Work around a bug where annotation text and label can be misaligned,
    // by slightly delaying first call to getData
    setTimeout(this.getData, 500)

    // Check if it's for print and show past events
    if (this.forPrint) {
      this.showPastEvents = true
    }
  },
  activated() {
    this.cardWidth = '99.9%'

    setTimeout(() => {
      this.cardWidth = '100%'
    }, 100)
  },
  methods: {
    getData: debounce(function () {
      this.dataLoading = true
      this.error = false

      this.chartOptions.legend.show = false
      this.chartData = {
        SHOWDATA: {
          DATA: [],
        },
        EVENTSDATA: {
          X: [],
          Y: [],
          POINTS: [],
        },
      }
      this.eventsDataLive.CONTENT = []
      this.maturityInfo = {
        payoff: '',
        duration: '',
      }

      this.$http
        .get(`report.cfc?method=getProductResultsEvents&productid=${this.productId}`
          + `&isfuture=${this.isFuture}&pcshift=${this.parameters.shift}&pcdrift=${this.parameters.drift}`)
        .then(response => {
          this.chartOptions.legend.show = true
          this.chartData = response.data.DATA.EVENTSCHART
          this.maturityInfo = response.data.DATA.MATURITYINFO
          this.isLive = response.data.DATA.EVENTSCHART.ISLIVE
          this.eventsDataLive = response.data.DATA.EVENTS

          this.$emit('events-data-loaded', this.eventsDataLive)
        })
        .catch(() => {
          this.error = true
        })
        .then(() => {
          this.dataLoading = false
        })
    }, 500),
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    customSortCompare(aRow, bRow, key) {
      const a = aRow[key][0]
      const b = bRow[key][0]

      if (key === 'probability') {
        if (typeof a === 'number' && typeof b === 'number') {
          // eslint-disable-next-line no-nested-ternary
          return a < b ? -1 : a > b ? 1 : 0
        } if (typeof a === 'number' && typeof b !== 'number') {
          return 1
        }
        return -1
      }

      if (key === 'eventname') {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : a > b ? 1 : 0
      }

      return null
    },
    sortContent(eventsData, sortBy, sortDesc) {
      const sortedContent = cloneDeep(eventsData)

      const tempSorted = sortedContent.map(data => {
        let sortables = []
        data[sortBy].forEach((p, index) => {
          sortables.push({
            probability: data.probability[index],
            color: data.color[index],
            eventname: data.eventname[index],
            level: data.level[index],
            spot: data.level[index],
            status: data.status[index],
          })
        })

        // Sort array of sortable objects based on the sortBy
        if (sortBy === 'eventname') {
          sortables = sortables.sort((a, b) => {
            if (a.eventname < b.eventname) {
              return sortDesc ? 1 : -1
            }
            if (a.eventname > b.eventname) {
              return sortDesc ? -1 : 1
            }
            return 0
          })
        } else {
          sortables = sortables.sort((a, b) => (sortDesc ? b[sortBy] - a[sortBy] : a[sortBy] - b[sortBy]))
        }
        return sortables
      })

      tempSorted.forEach((data, index) => {
        sortedContent[index].probability = data.map(d => d.probability)
        sortedContent[index].color = data.map(d => d.color)
        sortedContent[index].eventname = data.map(d => d.eventname)
        sortedContent[index].level = data.map(d => d.level)
        sortedContent[index].spot = data.map(d => d.spot)
        sortedContent[index].status = data.map(d => d.status)
      })
      return sortedContent
    },
  },
}

</script>

<style lang="scss" scoped>
@import '@core/scss/core.scss';

.shift-drift-button {
  width: 80px;
}

.shift-drift-input {
  width: 90px;
}

.shift-drift-input input, .shift-drift-input .input-group-text {
  border-color: $primary !important;
}

.shift-drift-input.disabled .input-group-text {
  opacity: 0.5 !important;
}

.shift-drift-input-label {
  margin: 0 0 4px 4px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
.events-collapse ::v-deep .collapse-title {
  font-weight: 500;
  font-size: 1.285rem;
}

.dark-layout .events-collapse ::v-deep .collapse-title {
  color: #d0d2d6;
}

.events-collapse ::v-deep div.card-header.collapsed::after {
  transform: rotate(0deg) scale(2, 2);
}

.events-collapse ::v-deep div.card-header::after {
  transform: rotate(180deg) scale(2, 2);
}
</style>
