<template>
  <b-card no-body>
    <template #header>
      <b-card-title>{{ title }}</b-card-title>
    </template>

    <b-card-sub-title
      v-if="explanation"
      class="card-body-padding"
      v-html="explanation"
    />

    <b-table
      v-if="!loading"
      responsive
      :thead-class="$attrs['table-header'] === undefined ? 'd-none' : ''"
      :fields="fields"
      :items="items"
    >
      <template
        v-for="col in productColumns"
        #[`cell(${col})`]="data"
      >
        <div
          :key="col"
          class="d-flex flex-row align-items-center"
        >
          <span style="min-width: 50%">{{ data.item[col] }}</span>
          <feather-icon
            v-if="data.item[`${col}id`] && data.item[`${col}id`].length > 0 && showInfoIcon"
            size="16"
            icon="InfoIcon"
            class="text-primary"
            style="cursor: pointer"
            @click="$emit('onShowProducts', data.item[`${col}id`])"
          />
        </div>
      </template>
      <template #cell(status)="data">
        <div v-if="data.item.color !== ''">
          <feather-icon
            size="18"
            :class="`text-${data.item.color}`"
            :icon="data.item.status"
          />
        </div>
        <div v-else>
          {{ data.item.status }}
        </div>
      </template>
    </b-table>

    <loading-container v-if="loading" />
    <no-data-container v-if="!loading && (!items || items.length < 1)" />

    <template
      v-if="link && !loading && items && items.length > 1"
      #footer
    >
      <div class="px-50 d-flex align-items-center">
        <b-link
          :href="link.href"
          target="_blank"
        >
          {{ link.text }}
        </b-link>
        <feather-icon
          icon="ExternalLinkIcon"
          class="ml-50"
        />
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BTable, BLink,
} from 'bootstrap-vue'

import LoadingContainer from '@/views/components/LoadingContainer.vue'
import NoDataContainer from '@/views/components/NoDataContainer.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BLink,
    LoadingContainer,
    NoDataContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => ['FIELD', 'VALUE'],
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    link: {
      type: Object,
      default: () => null,
    },
    showInfoIcon: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    productColumns() {
      if (this.items.length < 1) return []
      const columns = []
      this.fields.forEach(field => {
        Object.entries(field).forEach(entry => {
          const searchKey = `${entry[1]}id`
          this.items.some(item => {
            if (Object.hasOwnProperty.call(item, searchKey)) {
              columns.push(entry[1])
              return true
            }
            return false
          })
        })
      })
      return columns
    },
  },
}
</script>

<style>

</style>
