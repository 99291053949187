<template>
  <b-card title="Disclaimer">
    <template v-if="brand === 'srp'">
      <p>
        This report has been created for the StructrPro service for its professional subscriber clients.
        StructrPro is a joint venture between Structured Retail Products
        (<b-link href="https://www.structuredretailproducts.com">
          www.structuredretailproducts.com
        </b-link>)
        and FVC
        (<b-link href="https://www.futurevc.co.uk">
          www.futurevc.co.uk
        </b-link>),
        two UK based structured products specialist companies.
      </p>
      <p>
        The independent analysis presented on this service is based on product details and market data
        to calculate past events and possible future outcomes. This data is aimed at financial
        professionals as part of their market intelligence or product evaluation process. This data is not
        intended for investors in any jurisdiction nor is it a recommendation to buy or sell securities.
      </p>
      <p>
        All data is presented in good faith, however other methodologies or assumptions might yield
        significantly different results. No responsibility of any kind is accepted for any usage of this
        data or application.
      </p>
      <p>
        All data, methodologies and calculations are copyright and the exclusive property of
        Structured Retail Products and FVC. Data download and reproduction is limited to the terms of
        subscriber agreements.
      </p>
    </template>
    <template v-else-if="brand === 'se'">
      <p>
        This report has been created for the Structured Edge Plus service for its professional subscriber clients.
        Structured Edge is the UK’s most widely used structured products analysis service used by many thousands
        of financial advisers. It is provided by FVC
        (<b-link href="https://www.futurevc.co.uk">
          www.futurevc.co.uk
        </b-link>),
        a UK based company specialising in structured products.
      </p>
      <p>
        The independent analysis presented on this service is based on product details and market data to calculate
        past events and possible future outcomes. This data is aimed at financial professionals as part of their
        market intelligence or product evaluation process. This data is not intended for investors in any jurisdiction
        nor is it a recommendation to buy or sell securities.
      </p>
      <p>
        All data is presented in good faith, however other methodologies or assumptions might yield significantly
        different results. No responsibility of any kind is accepted for any usage of this data or application.
      </p>
      <p>
        All data, methodologies and calculations are copyright and the exclusive property of FVC. Data download and
        reproduction is limited to the terms of subscriber agreements.
      </p>
    </template>
  </b-card>
</template>

<script>
import { BCard, BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BLink,
  },
  setup() {
    // Brand details
    const { brand } = $themeConfig.app
    return {
      brand,
    }
  },
}
</script>
