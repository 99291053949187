<template>
  <b-row
    v-if="appBrand === 'srp'"
    class="mb-2"
  >
    <b-col cols="3" />
    <b-col cols="6">
      <div class="d-flex justify-content-center align-items-center">
        <b-img
          :src="srp.appLogo"
          alt="StructrPro"
          width="200px"
        />
        <span class="h1 header-text">{{ reportType }} Report</span>
      </div>
    </b-col>
    <b-col
      cols="3"
      class="text-right"
    >
      <div class="d-flex justify-content-end align-items-center">
        <span>Powered by</span>
        <b-img
          :src="srp.companyLogo1"
          alt="SRP"
          class="ml-1"
          style="max-width: 54px;"
        />
        <b-img
          :src="srp.companyLogo2"
          alt="FVC"
          class="ml-1"
          style="max-width: 54px"
        />
      </div>
    </b-col>

  </b-row>
  <div
    v-else
    class="d-flex justify-content-center align-items-center mb-2"
  >
    <b-img
      :src="se.appLogo"
      alt="logo"
      style="max-width: 72px"
    />
    <span class="h1 header-text">StructuredEdge {{ reportType }} Report</span>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'

const srp = {
  appLogo: require('@/assets/images/logo/structrpro-logo.png'),
  companyLogo1: require('@/assets/images/logo/srp-logo-2.png'),
  companyLogo2: require('@/assets/images/logo/fvc-logo.png'),
}

const se = {
  appLogo: require('@/assets/images/logo/fvc-logo.png'),
}
export default {
  components: {
    BRow,
    BCol,
    BImg,
  },
  props: {
    reportType: {
      type: String,
      required: true,
    },
  },
  computed: {
    appBrand() {
      return $themeConfig.app.brand
    },
    srp() {
      return srp
    },
    se() {
      return se
    },
  },
}
</script>
<style lang="scss" scoped>
.header-text {
  font-size: 1.7rem;
  margin: 0 0 0 10px;
}
</style>
