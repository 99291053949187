<template>
  <div v-if="!reportError">
    <b-row>
      <b-col>
        <b-card text-variant="warning">
          <b-card-title class="mb-0 d-flex justify-content-between">
            <span class="align-self-center">{{ report.PRODUCTNAME }}</span>
            <div>
              <b-button
                variant="primary"
                @click.prevent="addProductToPortfolio.show = true"
              >
                Add To Portfolio
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                :disabled="pdfDownload.loading"
                @click.prevent="downloadPdf"
              >
                <b-spinner
                  v-if="pdfDownload.loading"
                  small
                />
                {{ pdfDownload.loading ? 'Generating PDF' : 'Download as PDF' }}
              </b-button>
              <!-- <b-button
                variant="success"
                class="ml-1"
                :disabled="spreadsheetDownload.loading"
                @click.prevent="downloadSpreadsheet"
              >
                <b-spinner
                  v-if="spreadsheetDownload.loading"
                  small
                />
                {{ spreadsheetDownload.loading ? 'Generating Spreadsheet' : 'Download as Spreadsheet' }}
              </b-button> -->
            </div>
          </b-card-title>
          <b-card-text
            v-if="report.basicinfo.titlemessage"
            class="mt-2"
          >
            {{ report.basicinfo.titlemessage }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-card no-body>
          <template #header>
            <b-card-title>Product Terms</b-card-title>
          </template>

          <b-card-sub-title class="card-body-padding">
            Basic terms and underlyings
          </b-card-sub-title>

          <b-table
            v-if="!loading"
            responsive
            :thead-class="'d-none'"
            :fields="defaultFields"
            :items="report.PRODUCTTERMS"
          />

          <loading-container v-if="loading" />
          <no-data-container v-if="!loading && (!report.PRODUCTTERMS || report.PRODUCTTERMS.length < 1)" />
        </b-card>
      </b-col>

      <b-col lg="4">
        <report-table-card
          title="Product Information"
          explanation="Dates, status and links"
          :items="report.PRODUCTINFORMATION"
          :loading="loading"
          :link="productExternalLink"
        />
      </b-col>

      <b-col lg="4">
        <report-table-card
          title="Product Features"
          explanation="Dynamic product feature list"
          :items="report.PRODUCTFEATURES"
          :loading="loading"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="6"
      >
        <b-card no-body>
          <template #header>
            <b-card-title>{{ 'Underlying Asset'+(report.statedata_underlyingtable.content.length>1?'s':'') }} </b-card-title>
          </template>
          <template v-if="loading">
            <loading-container />
          </template>
          <template v-else>
            <b-table
              :class="{ 'h-160 custom-scrollbar': report.statedata_underlyingtable.content.length > 3, 'product-snapshot-table': true, }"
              responsive
              :sticky-header="true"
              :fields="report.statedata_underlyingtable.header"
              :items="report.statedata_underlyingtable.content"
            />
          </template>
        </b-card>

        <report-table-card
          v-if="!reportInfo.TERMINATION.ISTERMINATED"
          title="Product Snapshot"
          explanation="Key product features and their current status"
          table-header
          :fields="report.statedata_covertable.header"
          :items="report.statedata_covertable.content"
          :loading="loading"
        />
      </b-col>

      <b-col lg="6">
        <b-card
          v-if="!reportInfo.TERMINATION.ISTERMINATED"
          no-body
          class="card-min-height"
        >
          <template #header>
            <b-card-title>Payoff Profile</b-card-title>
          </template>
          <b-card-body>
            <b-card-sub-title class="pb-2">
              Chart of payoff versus underlying
            </b-card-sub-title>
            <vue-apex-charts
              type="scatter"
              height="100%"
              :options="chartOptions.scatter"
              :series="report.PAYOFFCHART.SHOWDATA"
            />
          </b-card-body>
        </b-card>

        <div
          v-if="reportInfo.TERMINATION.ISTERMINATED"
          class="d-flex flex-column py-2 my-3 justify-content-center align-items-center"
        >
          <span class="font-weight-bold h4">
            This product has matured, therefore some figures are not shown
          </span>
        </div>
      </b-col>
    </b-row>

    <events-chart :product-id="productId" />

    <b-row
      class="match-height"
    >
      <b-col
        v-if="!reportInfo.TERMINATION.ISTERMINATED"
        lg="6"
      >
        <b-card no-body>
          <template #header>
            <b-card-title> Payoff Distribution
            </b-card-title>
          </template>
          <b-card-body>
            <b-card-sub-title class="pb-2">
              Current distribution of outcomes and returns at maturity
            </b-card-sub-title>
            <b-form-radio-group
              v-model="selectedDistribution"
              :options="distributionOptions"
              name="distribution-selection"
              buttons
              button-variant="outline-primary"
              size="sm"
              class="mb-1"
            />
            <vue-apex-charts
              v-if="selectedDistribution === 'outcomes'"
              type="bar"
              height="350"
              :options="returnChartAbsOptions"
              :series="report.RETURNCHARTABS.SHOWDATA"
            />
            <vue-apex-charts
              v-if="selectedDistribution === 'returns'"
              type="bar"
              height="350"
              :options="returnChartAnnOptions"
              :series="report.RETURNCHARTANN.SHOWDATA"
            />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col :lg="reportInfo.TERMINATION.ISTERMINATED ? 12 : 6">
        <b-card no-body>
          <template #header>
            <b-card-title>Capital Chart</b-card-title>
          </template>
          <b-card-body>
            <b-card-sub-title class="pb-2">
              Time evolution of probabilities of product return versus capital
            </b-card-sub-title>
            <vue-apex-charts
              type="area"
              height="360"
              :options="chartOptions.area"
              :series="report.CAPITALCHART.SHOWDATA.DATA"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- Add Product to Portfolio Modal -->
    <b-modal
      v-model="addProductToPortfolio.show"
      centered
      title="Add Product To Portfolio"
      hide-footer
    >
      <add-product-to-portfolio-modal-content
        :product="{productid: productId, productname: report.PRODUCTNAME}"
        @portfolioUpdated="onProductAddedToPortfolio"
      />
    </b-modal>
  </div>
  <error-display
    v-else
    @action-clicked="loadProductReport"
  />
</template>

<script>
import ProductReportMixin from '@/mixins/ProductReportMixin.vue'

export default {
  mixins: [ProductReportMixin],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';

.dark-layout .product-snapshot-table th {
  color: #d0d2d6 !important;
}
</style>

<style lang="scss" scoped>
.h-160 {
  max-height: 160px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 14px;
}
.card-min-height {
  min-height: 460px;
}
</style>
