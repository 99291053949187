<script>
import { $themeColors, $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardBody, BCardText, BTable, BFormRadioGroup, BButton, BSpinner, BLink,
} from 'bootstrap-vue'
import strings from '@/strings'
import VueApexCharts from 'vue-apexcharts'
import NoDataContainer from '@/views/components/NoDataContainer.vue'
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import AddProductToPortfolioModalContent from '@/views/components/AddProductToPortfolioModalContent.vue'
import dayjs from 'dayjs'
import ReportPrintBrandHeader from '@/views/print/ReportPrintBrandHeader.vue'
import ReportTableCard from '@/views/components/ReportTableCard.vue'
import EventsChart from '@/views/components/EventsChart.vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'
import Disclaimer from '@/views/components/Disclaimer.vue'

export default {
  components: {
    ReportPrintBrandHeader,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardText,
    BTable,
    BFormRadioGroup,
    BButton,
    BSpinner,
    BLink,
    VueApexCharts,
    ReportTableCard,
    EventsChart,
    LoadingContainer,
    NoDataContainer,
    ErrorDisplay,
    AddProductToPortfolioModalContent,
    Disclaimer,
  },
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      defaultFields: ['FIELD', 'VALUE'],
      report: {
        PRODUCTNAME: '',
        REPORTDATES: [],
        SUMMARY: [],
        PRODUCTTERMS: [],
        PRODUCTINFORMATION: [],
        PRODUCTFEATURES: [],
        SCORECARD: {
          HEADER: [],
          CONTENT: [],
        },
        CAPITALCARD: {
          HEADER: [],
          CONTENT: [],
        },
        CAPITALCHART: {
          SHOWDATA: {
            DATA: [],
          },
        },
        PAYOFFCHART: {
          SHOWDATA: [],
        },
        RETURNCHARTANN: {
          XAXIS: [],
          SHOWDATA: [],
        },
        RETURNCHARTABS: {
          XAXIS: [],
          SHOWDATA: [],
        },
        basicinfo: {
          titlemessage: '',
        },
        statedata_covertable: {
          header: [],
          content: [],
        },
        statedata_underlyingtable: {
          header: [],
          content: [],
        },
      },
      reportInfo: {
        TERMINATION: {
          ISTERMINATED: false,
        },
      },
      chartOptions: {
        scatter: {
          noData: {
            text: 'Loading...',
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoomin: false,
                zoomout: false,
              },
            },
            zoom: {
              type: 'xy',
              enabled: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          colors: [$themeColors.primary, $themeColors.danger],
          markers: {
            size: [3, 5],
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          xaxis: {
            title: {
              text: 'Underlying',
            },
            tickAmount: 5,
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(1)
              },
            },
          },
          yaxis: {
            title: {
              text: 'Payoff',
            },
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(0)
              },
            },
          },
        },
        bar: {
          noData: {
            text: 'Loading...',
          },
          chart: {
            toolbar: {
              show: false,
            },
            stacked: true,
          },
          colors: [$themeColors.success, $themeColors.warning, $themeColors.danger],
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'left',
            markers: {
              radius: 12,
            },
          },
          xaxis: {
            tickAmount: 5,
          },
          yaxis: {
            title: {
              text: 'Probability',
            },
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(0)
              },
            },
          },
        },
        area: {
          noData: {
            text: 'Loading...',
          },
          chart: {
            stacked: true,
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoomin: false,
                zoomout: false,
              },
            },
            zoom: {
              type: 'xy',
              enabled: true,
            },
          },
          colors: [$themeColors.success, $themeColors.warning, $themeColors.danger],
          fill: {
            gradient: {
              shadeIntensity: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              // set to false to remove horizontal lines through graph
              lines: {
                show: true,
              },
            },
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            labels: {},
          },
          stroke: {
            width: 3,
            curve: 'smooth',
          },
          theme: {},
          tooltip: {
            x: {
              formatter: val => this.formatDate(val),
            },
            shared: false,
            marker: false,
          },
          xaxis: {
            type: 'datetime',
            axisTicks: {
              show: true,
            },
            tooltip: {
              enabled: true,
              formatter: val => this.formatDate(val),
            },
            labels: {
              show: true,
              showDuplicates: false,
              formatter: val => this.formatDate(val),
            },
          },
          yaxis: [
            {
              title: {
                text: 'Probability',
              },
              max: 100,
              seriesName: 'Close Price',
              axisBorder: {
                show: true,
              },
              axisTicks: {
                show: true,
              },
              labels: {
                formatter(val) {
                  return parseFloat(val).toFixed(0)
                },
              },
              opposite: false,
            },
          ],
        },
      },
      selectedDistribution: 'outcomes',
      distributionOptions: [
        { text: 'Outcomes', value: 'outcomes' },
        { text: 'Returns', value: 'returns' },
      ],
      loading: false,
      addProductToPortfolio: {
        show: false,
        loading: false,
        saveLoading: false,
        product: null,
        portfolios: [],
      },
      reportError: null,
      reportLoaded: false,
      eventChartLoaded: false,
      printReady: false, // Flag to add print ready selector class to be used by puppeteer
      pdfDownload: {
        loading: false,
        error: false,
      },
      spreadsheetDownload: {
        loading: false,
        error: false,
      },
    }
  },
  computed: {
    date() {
      return dayjs().format('YYYY-MM-DD HH:mm')
    },
    scorecard() {
      return this.transformHeaderAndContent(this.report.SCORECARD)
    },
    returnChartAnnOptions() {
      const options = { ...this.chartOptions.bar }
      options.xaxis = { ...options.xaxis }
      options.xaxis.categories = this.report.RETURNCHARTANN.XAXIS
      return options
    },
    returnChartAbsOptions() {
      const options = { ...this.chartOptions.bar }
      options.xaxis = { ...options.xaxis }
      options.xaxis.categories = this.report.RETURNCHARTABS.XAXIS
      return options
    },
    portfolioIn() {
      return this.addProductToPortfolio.portfolios.filter(portfolio => portfolio.in !== false)
    },
    portfolioNotIn() {
      return this.addProductToPortfolio.portfolios.filter(portfolio => portfolio.in === false)
    },
    productExternalLink() {
      let link
      if ($themeConfig.app.brand === 'srp') {
        link = {
          href: `https://www.structuredretailproducts.com/product/display?productId=${this.productId}`,
          text: 'Product on SRP',
        }
      } else {
        link = null
      }
      return link
    },
    appName() {
      return $themeConfig.app.appName
    },
    appBrand() {
      return $themeConfig.app.brand
    },
    fullReportLink() {
      const link = {
        href: `${process.env.BASE_URL}products/report/${this.productId}`,
        text: `${window.location.host}${process.env.BASE_URL}products/report/${this.productId}`,
      }
      return link
    },
  },
  watch: {
    reportLoaded() {
      if (this.reportLoaded && this.eventChartLoaded) {
        this.generateReport()
      }
    },
    eventChartLoaded() {
      if (this.reportLoaded && this.eventChartLoaded) {
        this.generateReport()
      }
    },
  },
  mounted() {
    // Check if it's a print page
    if (this.$route.path.includes('print')) {
      // Increase browser window zoom
      document.body.style.zoom = '115%'

      // Set to light theme
      this.setThemeToLight()
    }
  },
  created() {
    this.loadProductReport()
    this.loadPortfoliosByProduct(this.productId)
  },
  methods: {
    generateReport() {
      setTimeout(() => {
        this.printReady = true
      }, 1000)
    },
    setThemeToLight() {
      this.$store.commit('appConfig/UPDATE_SKIN', 'light')
    },
    loadProductReport() {
      this.loading = true

      // Reset error flag
      this.reportError = null

      // Clear data of payoff distribution chart
      this.report.RETURNCHARTABS.SHOWDATA = []

      this.$http
        .get(`report.cfc?method=getProductResults&productid=${this.productId}`)
        .then(response => {
          this.report = response.data.DATA
          this.reportInfo = response.data.INFO
          this.reportLoaded = true
        })
        .catch(error => {
          console.log('Error loading product report: ', error)
          this.reportError = true
        })
        .then(() => {
          this.loading = false
        })
    },
    transform1D(a) {
      // transform 1D array
      const fields = ['index', 'value']
      const items = []
      a.forEach((value, index) => {
        items.push({
          index: index + 1,
          value,
        })
      })
      return { fields, items }
    },
    transformHeaderAndContent(data) {
      // transform 1D header and 2D content
      const { HEADER: header, CONTENT: content } = data

      const fields = []
      const items = []

      if (header.length === 0) {
        return { fields, items }
      }

      for (let i = 0; i < header.length; i += 1) {
        fields.push({
          key: `col${i + 1}`,
          label: header[i],
        })
      }

      content.forEach(row => {
        const entry = {}
        row.forEach((value, index) => {
          entry[`col${index + 1}`] = value
        })
        items.push(entry)
      })
      return { fields, items }
    },
    loadPortfoliosByProduct(id) {
      this.addProductToPortfolio.loading = true

      this.$http.get(`portfolio.cfc?method=getPortfoliosByProduct&productId=${id}`)
        .then(response => {
          this.addProductToPortfolio.portfolios = response.data.portfolios
        }).catch(() => {
          this.$bvToast.toast('Fetching Portfolios Failed', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        }).then(() => {
          this.addProductToPortfolio.loading = false
        })
    },
    onProductAddedToPortfolio(portfolioIds) {
      this.addProductToPortfolio.show = false

      this.$bvToast.toast('Product Portfolio Updated', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })

      // Update store value
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
      this.$store.commit('app/ADD_UPDATED_PORTFOLIOS', portfolioIds)
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    downloadPdf() {
      this.pdfDownload.loading = true

      this.$http.get(`report.cfc?method=getProductReportPdf&productid=${this.productId}`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url

          // Get file name from response header Content-Disposition
          // eslint-disable-next-line prefer-destructuring
          a.download = response.headers['content-disposition'].match(/"([^']+)"/)[1]

          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }).catch(() => {
        // Show error toast
          this.pdfDownload.error = true
          this.$bvToast.toast('PDF generation failed. Please try again.', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        }).finally(() => {
          this.pdfDownload.loading = false
        })
    },
    downloadSpreadsheet() {
      this.spreadsheetDownload.loading = true

      this.$http.get(`report.cfc?method=getProductReportSpreadsheet&productid=${this.productId}`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url

          // Get file name from response header Content-Disposition
          // eslint-disable-next-line prefer-destructuring
          a.download = response.headers['content-disposition'].match(/"([^']+)"/)[1]

          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }).catch(() => {
        // Show error toast
          this.spreadsheetDownload.error = true
          this.$bvToast.toast('Spreadsheet generation failed. Please try again.', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        }).finally(() => {
          this.spreadsheetDownload.loading = false
        })
    },
  },
}
</script>
