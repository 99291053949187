<template>
  <div>
    <p>{{ product ? product.productname : 'Product Not Selected' }}</p>

    <loading-container v-if="loading" />
    <div v-else>
      <b-form-group
        class="mb-0"
        label="Portfolios"
      >
        <b-form-checkbox
          v-for="option in mappedPortfolio"
          :key="option.portfolioId"
          v-model="option.in"
          :value="Boolean(option.portfolioId)"
          name="flavour-3a"
          :disabled="option.productCount >= maxNumberOfProductsInPortfolio && !option.in"
        >
          {{ option.portfolioName }}
        </b-form-checkbox>
      </b-form-group>

      <div class="text-center mt-1">
        <b-button
          variant="primary"
          class="mt-1"
          :disabled="saveLoading"
          @click.prevent="updateProductPortfolio"
        >
          SAVE CHANGES <b-spinner
            v-if="saveLoading"
            small
          />
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import {
  BFormGroup,
  BFormCheckbox,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import strings from '@/strings'

export default {
  components: {
    LoadingContainer,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BSpinner,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      portfolios: [],
      in: [],
      out: [],
    }
  },
  computed: {
    portfolioIn() {
      return this.portfolios.filter(portfolio => portfolio.in !== false)
    },
    portfolioNotIn() {
      return this.portfolios.filter(portfolio => portfolio.in === false)
    },
    storePortfolios() {
      return this.$store.state.app.portfolios
    },
    maxNumberOfProductsInPortfolio() {
      return localStorage.getItem('maxNumberOfProductsInPortfolio') || this.$maxPortfolioSize
    },
    mappedPortfolio() {
      return this.portfolios.map(portfolio => {
        // Get matching portfolio from the store
        const storePortfolio = this.storePortfolios.find(p => p.portfolioId === portfolio.portfolioId)

        const newPortfolio = portfolio // { ...portfolio }
        newPortfolio.portfolioName = storePortfolio.portfolioName
        newPortfolio.productCount = storePortfolio.productCount
        return newPortfolio
      })
    },
  },
  mounted() {
    this.loadPortfoliosByProduct(this.product.productid)
  },
  methods: {
    updateProductPortfolio() {
      this.saveLoading = true

      const portfolioIds = this.portfolioIn.map(p => p.portfolioId)

      this.$http.post('portfolio.cfc?method=replacePortfoliosByProduct', new URLSearchParams({
        productId: this.product.productid,
        portfolioIds,
      }))
        .then(response => {
          // Emit event to indicate portfolio update
          this.$emit('portfolioUpdated', response.data.addPortfolioIds.concat(response.data.removePortfolioIds))
        })
        .catch(() => {
          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
        .then(() => {
          this.saveLoading = false
        })
    },
    loadPortfoliosByProduct(id) {
      this.loading = true

      this.$http.get(`portfolio.cfc?method=getPortfoliosByProduct&productId=${id}`)
        .then(response => {
          // Sort by portfolios `in` property
          const productPortfolios = response.data.portfolios
          productPortfolios.sort((a, b) => {
            if (a.in && !b.in) {
              return -1
            }
            return 1
          })
          this.portfolios = productPortfolios
        }).catch(() => {
          this.$bvToast.toast('Fetching Portfolios Failed', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        }).then(() => {
          this.loading = false
        })
    },
  },
}
</script>
